import {
	FETCH_PRODUCTS_SUCCESS,
	FETCH_FILTER_PRODUCTS_SUCCESS,
	FETCH_PRODUCTS_REQUEST,
	FETCH_PRODUCTS_FAILURE,
  } from "../actions/productActions";
  
  const initState = {
	products: [],
	filters: [],
	loading: false,
	error: null,
  };
  
  const productReducer = (state = initState, action) => {
	switch (action.type) {
	  case FETCH_PRODUCTS_REQUEST:
		return {
		  ...state,
		  loading: true,
		  error: null,
		};
	  case FETCH_PRODUCTS_SUCCESS:
	  case FETCH_FILTER_PRODUCTS_SUCCESS:
		console.log("FETCH_FILTER_PRODUCTS_SUCCESS");
		return {
		  ...state,
		  products: action.payload.products,
		  filters: action.payload.filters,
		  loading: false,
		};
	  case FETCH_PRODUCTS_FAILURE:
		return {
		  ...state,
		  loading: false,
		  error: action.payload,
		};
	  default:
		return state;
	}
  };
  
  export default productReducer;
  