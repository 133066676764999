import axios from "axios";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_FILTER_PRODUCTS_SUCCESS = "FETCH_FILTER_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080";

// TTL: 1 minute in milliseconds
const CACHE_TTL = 60 * 1000;
// Simple in-memory cache object
const cache = {};

const fetchProductsSuccess = (products) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products,
});

const fetchFIlterProductsSuccess = (data) => ({
  type: FETCH_FILTER_PRODUCTS_SUCCESS,
  payload: data,
});

const mapProduct = (
  {
    id,
    type,
    quantity,
    discountPercentage,
    title,
    articleNo,
    material,
    shape,
    finish,
    origin,
    usage,
    description,
    dimensions,
    category,
    color,
    height,
    width,
    depth,
    surface,
    images,
    mainImage,
    price,
    priceUnit,
    createdAt,
    updatedAt,
    supplierId
  },
  suppliers
) => {
  const supplier = suppliers.find((s) => s.id === supplierId) || {};

  return {
    id,
    type,
    quantity,
    supplier: {
      id: supplier.id,
      name: supplier.name,
      phoneNumber: supplier.phoneNumber,
      address: supplier.address,
      logoUrl: supplier.logoUrl,
      googleMapsUrl: supplier.googleMapsUrl,
    },
    sku: title,
    name: title,
    price,
    priceUnit,
    article: articleNo,
    material,
    shape,
    finish,
    dimensions,
    origin,
    usage,
    discount: discountPercentage,
    offerEnd: "October 5, 2020 12:11:00",
    new: false,
    rating: 4,
    saleCount: 54,
    category: ["tiles", "floor tiles", "roof tiles"],
    tag: ["tiles", "floor tiles", "roof tiles"],
    cpic: images[0],
    color: color,
    variation: [
      {
        color,
        image: mainImage,
        size: [
          {
            name: `${height} x ${width} x ${depth}`,
            stock: quantity,
          },
        ],
      },
    ],
    images: images,
    shortDescription: description,
    fullDescription: description,
    createdAt,
    updatedAt,
  };
};

export const fetchProducts = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_PRODUCTS_REQUEST }); // Start loading

    const cacheKey = "fetchProducts";
    const cachedEntry = cache[cacheKey];
    if (cachedEntry && (Date.now() - cachedEntry.timestamp < CACHE_TTL)) {
      console.log("Using cached data for fetchProducts");
      dispatch(fetchProductsSuccess(cachedEntry.data));
      return;
    }

    axios({
      method: "POST",
      url: `${API_BASE_URL}/items/v2/get`,
      headers: { "Content-Type": "application/json" },
      data: {},
      responseType: "json",
    })
      .then((response) => {
        const { products = [], suppliers = [], filters = [] } = response.data.data;
        const mappedProducts = products.map((product) => mapProduct(product, suppliers));
        const payload = { products: mappedProducts, filters: filters };
        // Cache the response with a timestamp
        cache[cacheKey] = {
          timestamp: Date.now(),
          data: payload,
        };
        dispatch(fetchProductsSuccess(payload));
      })
      .catch((err) => {
        console.error("Error fetching products: ", err);
        dispatch({ type: FETCH_PRODUCTS_FAILURE, payload: err.message });
      });
  };
};

export const fetchFilterProducts = (data) => {
  return (dispatch) => {
    dispatch({ type: FETCH_PRODUCTS_REQUEST }); // Start loading

    // Use JSON.stringify on data to form a unique cache key.
    const cacheKey = "fetchFilterProducts:" + JSON.stringify(data);
    const cachedEntry = cache[cacheKey];
    if (cachedEntry && (Date.now() - cachedEntry.timestamp < CACHE_TTL)) {
      console.log("Using cached data for fetchFilterProducts with data", data);
      dispatch(fetchFIlterProductsSuccess(cachedEntry.data));
      return;
    }

    axios({
      method: "POST",
      url: `${API_BASE_URL}/items/v2/get`,
      headers: { "Content-Type": "application/json" },
      data: { ...data },
      responseType: "json",
    })
      .then((response) => {
        const { products = [], suppliers = [], filters = [] } = response.data.data;
        const mappedProducts = products.map((product) => mapProduct(product, suppliers));
        const payload = { products: mappedProducts, filters: filters };
        // Cache the response with a timestamp
        cache[cacheKey] = {
          timestamp: Date.now(),
          data: payload,
        };
        dispatch(fetchFIlterProductsSuccess(payload));
      })
      .catch((err) => {
        console.error("Error fetching filtered products: ", err);
        dispatch({ type: FETCH_PRODUCTS_FAILURE, payload: err.message });
      });
  };
};
